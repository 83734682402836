<script setup lang="ts">
const props = defineProps({
  splitOffset: { type: String, default: '30%' },
  splitOffsetId: { type: String, required: true },
})
</script>

<template>
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
    <defs>
      <linearGradient :id="'prefix_' + props.splitOffsetId" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop :offset="props.splitOffset" style="stop-color: #32b6a8; stop-opacity: 1" />
        <stop :offset="props.splitOffset" style="stop-color: #daeeee; stop-opacity: 1" />
      </linearGradient>
    </defs>
    <g>
      <path
        d="M19.6701 9.7904L15.8921 13.0504L17.0431 17.9257C17.1066 18.1904 17.0902 18.4679 16.9961 18.7233C16.9019 18.9787 16.7342 19.2004 16.5141 19.3605C16.294 19.5206 16.0314 19.6119 15.7594 19.6228C15.4875 19.6337 15.2184 19.5638 14.9862 19.4218L10.7464 16.8125L6.5041 19.4218C6.27191 19.5629 6.00314 19.6322 5.73166 19.6209C5.46017 19.6096 5.19811 19.5182 4.97846 19.3582C4.75881 19.1983 4.5914 18.9769 4.49731 18.722C4.40322 18.4671 4.38665 18.19 4.4497 17.9257L5.60494 13.0504L1.82692 9.7904C1.62148 9.61284 1.4729 9.37869 1.39974 9.11719C1.32658 8.8557 1.33207 8.57844 1.41555 8.32005C1.49902 8.06166 1.65677 7.83359 1.86909 7.66432C2.08141 7.49504 2.3389 7.39208 2.60939 7.36827L7.56279 6.96864L9.47363 2.34435C9.57705 2.09232 9.75309 1.87675 9.97935 1.72503C10.2056 1.57332 10.4719 1.49231 10.7443 1.49231C11.0167 1.49231 11.283 1.57332 11.5092 1.72503C11.7355 1.87675 11.9115 2.09232 12.015 2.34435L13.925 6.96864L18.8784 7.36827C19.1494 7.39119 19.4076 7.49358 19.6207 7.66262C19.8338 7.83166 19.9923 8.05982 20.0763 8.31853C20.1603 8.57723 20.1661 8.85497 20.0929 9.11694C20.0197 9.37892 19.8709 9.61348 19.665 9.79124L19.6701 9.7904Z"
        :fill="'url(#' + 'prefix_' + props.splitOffsetId + ')'"
      />
    </g>
  </svg>
</template>

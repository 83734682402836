<template>
  <svg viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.873 1.12216C19.7891 0.91985 19.6662 0.736056 19.5112 0.581285C19.3565 0.426341 19.1727 0.303424 18.9704 0.219559C18.7681 0.135695 18.5512 0.0925293 18.3322 0.0925293C18.1132 0.0925293 17.8964 0.135695 17.694 0.219559C17.4917 0.303424 17.3079 0.426341 17.1532 0.581285L6.66685 11.0697L2.84644 7.24721C2.53374 6.93451 2.10963 6.75884 1.66741 6.75884C1.22518 6.75884 0.801071 6.93451 0.488372 7.24721C0.175673 7.5599 0 7.98402 0 8.42624C0 8.86847 0.175673 9.29258 0.488372 9.60528L5.48781 14.6047C5.64258 14.7597 5.82638 14.8826 6.02869 14.9664C6.23099 15.0503 6.44785 15.0935 6.66685 15.0935C6.88585 15.0935 7.1027 15.0503 7.30501 14.9664C7.50732 14.8826 7.69111 14.7597 7.84588 14.6047L19.5112 2.93936C19.6662 2.78458 19.7891 2.60079 19.873 2.39848C19.9568 2.19618 20 1.97932 20 1.76032C20 1.54132 19.9568 1.32447 19.873 1.12216Z"
        fill="#DAEEEE"
      />
      <path
        d="M19.5112 0.581285C19.6662 0.736056 19.7891 0.91985 19.873 1.12216C19.9568 1.32447 20 1.54132 20 1.76032C20 1.97932 19.9568 2.19618 19.873 2.39848C19.7891 2.60079 19.6662 2.78458 19.5112 2.93936L7.84588 14.6047C7.69111 14.7597 7.50732 14.8826 7.30501 14.9664C7.1027 15.0503 6.88585 15.0935 6.66685 15.0935C6.44785 15.0935 6.23099 15.0503 6.02869 14.9664C5.82638 14.8826 5.64258 14.7597 5.48781 14.6047L0.488372 9.60528C0.175673 9.29258 0 8.86847 0 8.42624C0 7.98402 0.175673 7.5599 0.488372 7.24721C0.801071 6.93451 1.22518 6.75884 1.66741 6.75884C2.10963 6.75884 2.53374 6.93451 2.84644 7.24721L6.66685 11.0697L17.1532 0.581285C17.3079 0.426341 17.4917 0.303424 17.694 0.219559C17.8964 0.135695 18.1132 0.0925293 18.3322 0.0925293C18.5512 0.0925293 18.7681 0.135695 18.9704 0.219559C19.1727 0.303424 19.3565 0.426341 19.5112 0.581285Z"
        fill="#0E9B8C"
      />
    </g>
  </svg>
</template>
